<template>
  <v-card class="rounded-lg d-flex align-center font-weight-bold justify-center pa-2 fill-width fill-height orange lighten-4" light outlined @click.prevent="loadingStep = 0, dialogConfirmExport = true">
    <v-icon small class="mr-1">
      mdi-file-export
    </v-icon>
    Export
    <v-dialog
      v-model="dialogConfirmExport"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title class="justify-center">
          Export Data
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4" style="max-height: 340px;">
          <div v-if="loadingStep === 0">
            <v-card class="mb-4 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer" outlined @click.prevent="ProcessExport(true)">
              Download Data
            </v-card>
          </div>
          <div v-if="loadingStep === 1 || loadingStep === 2 || loadingStep === 3" class="d-flex flex-column align-center justify-center fill-width py-8">
            <v-progress-circular
              :size="40"
              :width="3"
              color="primary"
              indeterminate
            />
            <div class="pt-1">
              {{ loadingStep === 1 ? ('Request data') : (loadingStep === 2 ? 'Preparing data' : 'Preparing download') }} ...
            </div>
          </div>
          <div v-else-if="loadingStep === 4">
            <xlsx-workbook>
              <xlsx-sheet
                :sheet-name="sheet.name"
                :collection="sheet.data"
              />
              <xlsx-download :filename="'abc-data-' + (new Date()).toISOString() + '.xlsx'" :download="DownloadData">
                <v-btn depressed block color="primary" class="white--text text-capitalize rounded-pill" @click.prevent="DownloadData">
                  Download Data
                </v-btn>
              </xlsx-download>
            </xlsx-workbook>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="grey darken-1"
            outlined
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="dialogConfirmExport = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  props: {
    fromTo: {
      type: String,
      default: ''
    },
    paymentStatus: {
      type: Number,
      default: 1
    },
    paymentOption: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogConfirmExport: false,
    loadingStep: 0,
    sheet: {
      name: '',
      data: []
    }
  }),
  methods: {
    DownloadData () {
      this.dialogConfirmExport = false
    },
    async ProcessExport () {
      this.loadingStep = 0
      this.sheet.name = 'ABC FINANCE'
      this.sheet.data = []

      const query = `?page=0&limit=10000000000000&finance=${this.paymentStatus}&paymentOption=${this.paymentOption}${this.fromTo}`

      this.loadingStep = 1
      this.$store.dispatch('logistic/SHIPPING_GET', query)
        .then((res) => {
          if (res.status) {
            try {
              const data = res.data.data.data || []
              if (data.length) {
                this.ParsingData(data)
              } else {
                this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada data!' })
                this.loadingStep = 0
              }
            } catch {
              this.$store.dispatch('TOAST', { show: true, message: 'Gagal export data!' })
              this.loadingStep = 0
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            this.loadingStep = 0
          }
        })
    },
    ParsingData (data) {
      this.loadingStep = 2
      for (let a = 0; a < data.length; a++) {
        const obj = Object.assign({}, data[a])
        const o = {
          ID: obj.id,
          TTK: obj.ttk,
          'Bill To (ID)': obj.customer_billto || '',
          'Bill To': obj.customer_billto_name || '',
          Subtotal: parseFloat(obj.price) + parseFloat(obj.price_packaging) + parseFloat(obj.price_other) || 0,
          PPN: parseFloat(obj.price_ppn_value) || 0,
          Jumlah: parseFloat(obj.price_final) || 0,
          Dibayar: parseFloat(obj.amount_paid) || 0,
          Sisa: (parseFloat(obj.price_final) || 0) - (parseFloat(obj.amount_paid) || 0),
          Pembayaran: obj.payment_option || '',
          'Tanggal TTK': new Date(obj.created).toISOString()
        }
        this.sheet.data.push(o)
      }
      this.loadingStep = 3
      setTimeout(() => {
        this.loadingStep = 4
      }, 1000)
    },
    parsed (d) {
      if (d.SheetNames.length) {
        this.sheets = d.Sheets || {}
        this.sheetSelected = null
        this.dialogConfirmUpload = true
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman pada file!' })
      }
    }
  }
}
</script>
